















































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'AgentSetting',
})
export default class AgentSetting extends VueBase {
  private form = {
    vul_verifiy: '0',
    circuit_break: '0',
  }

  async getInfo() {
    const keys = []
    for (let key in this.form) {
      keys.push(key)
    }
    const res = await this.services.setting.profileGet({
      keys,
    })
    if (res.status === 201) {
      res.data.forEach((item: any) => {
        this.form[item.key] = item.value
      })
    } else {
      this.$message.error(res.msg)
    }
  }

  async profileModified(str: string) {
    let obj: any = {}
    obj = {
      value: String(this.form[str]),
      key: str,
    }
    const res: any = await (async (obj: any) => {
      switch (str) {
        case 'circuit_break':
          return await this.services.setting.profileModifiedCircuit(obj)
        case 'vul_verifiy':
          return await this.services.setting.profileModifiedVul(obj)
      }
    })(obj)
    if (res.status === 201) {
      this.$message.success(res.msg)
    } else {
      this.$message.error(res.msg)
    }
    this.getInfo()
  }

  created() {
    this.getInfo()
  }

  toSetting() {
    this.$router.push({ name: 'sysInfo' })
  }
}
